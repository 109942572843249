import {
  SSMClient,
  GetParameterCommand,
  ParameterNotFound,
  GetParameterCommandOutput,
} from "@aws-sdk/client-ssm"
import { env, stage } from "@tc/env-stage"

/**
 * Return anoutput parameter for a given app.
 *
 * `parameterName` must exist, else the function will throw an error.
 *
 * @param appName - name of project providing the parameter.
 * @param parameterName - the name of the prarameter
 * @param cacheDuration - Optional time to cache the parameter. Default is forever.
 * @returns The value of the parameter.
 */
interface CachedValue {
  value: string
  expires: number | null
}
const ssmCache: Record<string, CachedValue> = {}

export const appParameter = async (
  appName: string,
  parameterName: string,
  cacheDuration?: number,
): Promise<string> => {
  return await getParameter(env, appName, parameterName, cacheDuration)
}

export const stageParameter = async (
  appName: string,
  parameterName: string,
  cacheDuration?: number,
): Promise<string> => {
  return await getParameter(stage, appName, parameterName, cacheDuration)
}

export const getParameter = async (
  myEnv: string,
  appName: string,
  parameterName: string,
  cacheDuration?: number,
): Promise<string> => {
  const client = new SSMClient({})
  const name = `/${myEnv}/${appName}/${parameterName}`
  const now = Math.floor(Date.now() / 1000) // current epoch

  if (ssmCache[name]) {
    const expires = ssmCache[name].expires
    if (!expires || expires >= now) {
      return ssmCache[name].value
    }
  }

  const command = new GetParameterCommand({ Name: name })
  let result: GetParameterCommandOutput
  try {
    result = await client.send(command)
  } catch (err) {
    if (err instanceof ParameterNotFound) {
      return Promise.reject(new Error(`Parameter ${name} not found.`))
    } else {
      throw err
    }
  }

  if (result.Parameter && result.Parameter.Value) {
    ssmCache[name] = {
      value: result.Parameter.Value,
      expires: cacheDuration ? now + cacheDuration : null,
    }
    return result.Parameter.Value
  } else {
    return Promise.reject(new Error(`Parameter ${parameterName} not found.`))
  }
}

/**
 * This function appears to be unused.
 */
export const sstParameter = async (
  appName: string,
  parameterName: string,
): Promise<string> => {
  const client = new SSMClient({})
  const name = `/sst/${appName}/${env}/${parameterName}`
  if (ssmCache[name]) {
    return ssmCache[name].value
  }

  const command = new GetParameterCommand({ Name: name })
  let result: GetParameterCommandOutput
  try {
    result = await client.send(command)
  } catch (err) {
    if (err instanceof ParameterNotFound) {
      return Promise.reject(new Error(`Parameter ${name} not found.`))
    } else {
      throw err
    }
  }

  if (result.Parameter && result.Parameter.Value) {
    ssmCache[name] = {
      value: result.Parameter.Value,
      expires: null,
    }
    return result.Parameter.Value
  } else {
    return Promise.reject(new Error(`Parameter ${parameterName} not found.`))
  }
}
